<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Exam {{currentUser.session_default_text}}s</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Exam {{currentUser.session_default_text.toLowerCase()}}s
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                    @click="createExamSession()"
                    class="mt-1 btn btn-block btn-primary"
                    style="color:#fff;"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add exam {{ currentUser.session_default_text.toLowerCase() }}
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <form  class="row">

                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                      label="Period title"
                      v-model="search.name" clearable
                      outlined  v-on:keyup.enter="searchExamSession"
                      dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="2" v-if="currentUser.access_type == 'federal'">
                  <v-autocomplete
                      label="SCORE"
                      v-model="search.score_id"
                      item-text="name"
                      item-value="id"
                      :items="scores" v-on:keyup.enter="searchExamSession"
                      outlined clearable
                      dense>
                    <template v-slot:no-data>
                      <div class="no-data-auto-complete text-center">No data available </div>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-autocomplete
                      label="View"
                      v-model="search.event_type"
                      item-text="text"
                      item-value="value"
                      :items="event_types" v-on:keyup.enter="searchExamSession"
                      outlined clearable
                      dense>
                    <template v-slot:no-data>
                      <div class="no-data-auto-complete text-center">No data available </div>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-autocomplete
                      label="Visibility"
                      v-model="search.is_public_hidden"
                      outlined
                      item-text="name"
                      item-value="value" v-on:keyup.enter="searchExamSession"
                      :items="public_hidden_types" clearable
                      dense
                  >
                    <template v-slot:no-data>
                      <div class="no-data-auto-complete text-center">No data available </div>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-autocomplete
                      label="Location "
                      v-model="search.location_ids"
                      item-text="name" v-on:keyup.enter="searchExamSession"
                      item-value="id"
                      :items="locations"
                      outlined clearable
                      dense>
                    <template v-slot:no-data>
                      <div class="no-data-auto-complete text-center">No data available </div>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-autocomplete
                      label="Year"
                      v-model="search.enrolment_year_id"
                      item-text="year"
                      item-value="id" v-on:keyup.enter="searchExamSession"
                      :items="enrollments"
                      outlined clearable
                      dense>
                    <template v-slot:no-data>
                      <div class="no-data-auto-complete text-center">No data available </div>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-menu
                      ref="menuStartDate"
                      v-model="menuStartDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="search.start_date"
                          chips clearable
                          small-chips
                          label="Session start date"
                          readonly
                          outlined
                          dense
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="search.start_date"
                        @input="menuStartDate = false"
                        no-title
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-menu
                      ref="menuEndDate"
                      v-model="menuEndDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="search.end_date"
                          chips
                          small-chips
                          label="Session end date"
                          readonly
                          outlined
                          dense clearable
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="search.end_date"
                        @input="menuEndDate = false"
                        no-title
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-menu
                      ref="closingDate"
                      v-model="closingDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="search.closing_date"
                          chips
                          small-chips
                          label="Session closing date"
                          readonly
                          outlined
                          dense clearable
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="search.closing_date"
                        @input="closingDate = false"
                        no-title
                        scrollable
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-autocomplete
                      label="Family"
                      v-model="search.instrument_ids"
                      item-text="name" v-on:keyup.enter="searchExamSession"
                      item-value="id"
                      :items="instruments"
                      outlined clearable
                      dense>
                    <template v-slot:no-data>
                      <div class="no-data-auto-complete text-center">No data available </div>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-autocomplete
                      label="Syllabus "
                      v-model="search.syllabus_ids"
                      item-text="name" v-on:keyup.enter="searchExamSession"
                      item-value="id"
                      :items="syllabuses"
                      outlined clearable
                      dense>
                    <template v-slot:no-data>
                      <div class="no-data-auto-complete text-center">No data available </div>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-autocomplete
                      label="Grade"
                      v-model="search.grade_ids"
                      item-text="name"
                      item-value="id"
                      :items="grades" v-on:keyup.enter="searchExamSession"
                      outlined clearable
                      dense
                  >
                    <template v-slot:no-data>
                      <div class="no-data-auto-complete text-center">No data available </div>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-autocomplete
                      label="Status"
                      v-model="search.is_active"
                      outlined
                      item-text="name"
                      item-value="value" v-on:keyup.enter="searchExamSession"
                      :items="status" clearable
                      dense>
                    <template v-slot:no-data>
                      <div class="no-data-auto-complete text-center">No data available </div>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" :md="currentUser.access_type == 'federal' ? 10: 12">
                  <v-btn
                      @click.prevent="searchExamSession"
                      class="btn btn-primary w-35 float-right"
                      style="color: #fff;"
                  >
                      <v-icon small  elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                      Search
                  </v-btn>
                </v-col>
              </form>
            </div>

            <div class="table-responsive">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="isLoading">
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="isLoading"
                  type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table">
                <thead>
                  <tr>
                    <th style="max-width: 370px !important; white-space: pre-wrap;" class="px-3" :class="sortedClass('name')" @click="sortBy('name')">Period</th>
                    <th style="max-width: 370px !important; white-space: pre-wrap;">Start date</th>
                    <th style="max-width: 370px !important; white-space: pre-wrap;">Closing date</th>
                    <th style="max-width: 370px !important; white-space: pre-wrap;">Location</th>
                    <th style="max-width: 370px !important; white-space: pre-wrap;">Syllabus</th>
                    <th style="max-width: 270px !important; white-space: pre-wrap;">Grade</th>
                    <th style="max-width: 270px !important; white-space: pre-wrap;" v-if="currentUser.access_type == 'federal'">SCORE</th>
                    <th>Status</th>
                    <th class="pr-3 text-center">Options</th>
                  </tr>
                </thead>
                <tbody>
                  <template>
                  <tr v-for="exam_session in sortedItems" :key="exam_session.id" >
                    <td style="max-width: 370px !important; white-space: pre-wrap;" class="px-2">
                      <a @click="editExamSession(exam_session.id)" class="">
                        {{ exam_session.name }}
                      </a>
                      <span v-if="exam_session.total_candidates>0" class="badge badge-primary" title="Total schedules">{{exam_session.total_candidates}}</span>
                    </td>
                    <td style="max-width: 370px !important; white-space: pre-wrap;" class="text-left">
                      {{ exam_session.formatted_start_date }}
                    </td>
                    <td style="max-width: 370px !important; white-space: pre-wrap;" class="text-left">

                      <ul>
                        <li v-if="exam_session.formatted_end_date"><b>End date:</b>   {{ exam_session.formatted_end_date }}</li>
                        <li v-if="exam_session.formatted_closing_date"><b>Closing date:</b>   {{ exam_session.formatted_closing_date }}</li>
                        <li v-if="exam_session.late_closing_date_format"><b>Late closing date:</b>   {{ exam_session.late_closing_date_format }}</li>
                      </ul>


                    </td>
                    <td style="max-width: 370px !important; white-space: pre-wrap;" class="text-left">
                      <ul v-if="exam_session.locations">
                        <li v-for="location in exam_session.locations">
                          {{ location.name }}
                        </li>
                      </ul>
                      <span v-else>--</span>
                    </td>
                    <td class="text-left" style="max-width: 370px !important; white-space: pre-wrap;">
                      <ul v-if="exam_session.syllabuses">
                        <li v-for="syllabus in exam_session.syllabuses">
                          {{ syllabus.name }}
                        </li>
                      </ul>
                      <span v-else>--</span>
                    </td>
                    <td class="text-left" style="max-width: 270px !important; white-space: pre-wrap;">
                      <ul v-if="exam_session.grades">
                        <li v-for="grade in exam_session.grades">
                          {{ grade.name }}
                        </li>
                      </ul>
                      <span v-else>--</span>
                    </td>
                    <td class="text-left" style="max-width: 270px !important; white-space: pre-wrap;" v-if="currentUser.access_type == 'federal'">
                      {{ exam_session.score_purchase_prefix ? exam_session.score_purchase_prefix : '--' }}
                    </td>
                    <td>
                      <p>
                         <span class="badge"
                               v-bind:class="{ 'badge-success': exam_session.is_active, 'badge-danger': !exam_session.is_active }"
                         >{{ exam_session.enrolment_stage_text ? 'Active' : 'Inactive' }}</span>
                      </p>
                     <p>
                        <span class="badge badge-danger text-lg`" v-if="exam_session.enrolment_stage_text=='Closed'"
                        >{{ exam_session.enrolment_stage_text}}</span>
                       <span class="badge badge-info" v-if="exam_session.enrolment_stage_text=='Running_normal'"
                       >Running normal</span>
                       <span class="badge badge-warning" v-if="exam_session.enrolment_stage_text=='Running_late'"
                       >Running late</span>
                     </p>

                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="editExamSession(exam_session.id)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-edit"></i>
                                </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link" @click.prevent="deleteExamSession(exam_session.id, exam_session.has_candidate)">
                                <span class="navi-icon">
                                    <i class="fas fa-trash"></i>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="exam_sessions.length == 0" >
                    <td colspan="11" class="text-center"><strong>No Data Found</strong></td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                v-if="exam_sessions.length > 0"
                class="pull-right mt-7"
                @input="getAllExamSession"
                v-model="page"
                :total-rows="total"
                :per-page="perPage"
                first-number
                last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import ScoreService from "@/services/score/score/ScoreService";
import ExamSession from "@/services/practical-exam/exam-session/ExamSession";
import LocationService from "@/services/practical-exam/location/LocationService";
import InstrumentService from "@/services/product/instrument/InstrumentService";
import SyllabusService from "@/services/product/syllabus/SyllabusService";
import GradeService from "@/services/product/grade/GradeService";
import EnrolmentYearService from "@/services/setting/enrolment-year/EnrolmentYearService";
import AdminUserService from "@/services/admin-user/AdminUserService";
import ExamDayService from "@/services/practical-exam/exam-day/ExamDayService";

const adminUser=new AdminUserService();
const examSession = new ExamSession();
const location = new LocationService();
const instrument = new InstrumentService();
const syllabus = new SyllabusService();
const grade = new GradeService();
const enrolmentYear = new EnrolmentYearService();
const score = new ScoreService();
const examDay=new ExamDayService();

export default {
  name: "Index",
  data() {
    return {
      menuStartDate: false,
      menuEndDate: false,
      closingDate: false,
      total: null,
      perPage: null,
      page: null,
      exam_sessions: [],
      locations: [],
      instruments: [],
      syllabuses: [],
      grades: [],
      enrollments: [],
      scores: [],
      sort: {
        key: '',
        isAsc: false
      },
      search:{
        name: '',
        start_date: '',
        end_date: '',
        closing_date: '',
        location_ids: '',
        instrument_ids: '',
        syllabus: '',
        grade_ids: '',
        enrolment_year_id: '',
        score_id: '',
        is_active: '',
        event_type:'',
      },
      isLoading:false,
      status: [

        {name: 'Active', value: '1'},
        {name: 'Inactive', value: '0'},
      ],
      public_hidden_types: [

        {name: 'Yes', value: '1'},
        {name: 'No', value: '0'},
      ],
      currentUserDetail:{},
      currentYear:new Date().getFullYear(),
      year:{},
      event_types:[
        {text:'All',value:'all'},
        {text:'Upcoming',value:'upcoming'},
        {text:'Closed',value:'closed'},
      ],
    }
  },
  mounted(){

    this.getAllLocations();
    this.getAllInstruments();
    this.getAllSyllabuses();
    this.getAllGrades();
    this.getAllEnrolmentYear();
    this.getAllScore();
    this.getCurrentUser();
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
    routeTab(){
      if(this.currentUser){
        let title = 'Exam ' + this.currentUser.session_default_text+'s';
        return {
          title: title
        }
      }

    },
    sortedItems() {
      const list = this.exam_sessions.slice();
      if (!!this.sort.key) {
        list.sort((a, b) => {
          a = a[this.sort.key]
          b = b[this.sort.key]
          return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1)
        });
      }
      return list;
    },

  },
  methods: {
    sortedClass(key) {
      return this.sort.key === key ? `sorted ${this.sort.isAsc ? 'asc' : 'desc'}` : '';
    },
    sortBy(key) {
      this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false;
      this.sort.key = key;
    },

    getAllExamSession(){

      if(this.search.end_date < this.search.start_date) {
        this.$snotify.error("View up to date must be greater than view from date");
        return;
      }

      this.isLoading=false;
      examSession
      .paginate(this.search, this.page)
      .then((response) => {
        this.exam_sessions = response.data.data;
        this.page = response.data.meta.current_page;
        this.total = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
        this.isLoading=false;
      })
      .catch((err) => {

      });
    },
    getAllLocations() {
      location.all().then(response => {
        this.locations = response.data.locations;
      });
    },
    getAllInstruments() {
      instrument.all().then(response => {
        this.instruments = response.data.instruments;
      });
    },
    getAllSyllabuses() {
      syllabus.all().then(response => {
        this.syllabuses = response.data.syllabuses;
      });
    },
    getAllGrades() {
      grade.all().then(response => {
        this.grades = response.data.grades;
      });
    },
    getAllEnrolmentYear() {
      enrolmentYear.all().then(response => {
        this.enrollments = response.data;
        this.year = this.enrollments.filter(item => item.year == this.currentYear);
        if(this.currentUser.access_type=='score') {
          this.search.enrolment_year_id = this.currentUser.score_enrolment_year;
          this.getAllExamSession();
        }else{
          if(this.year.length>0){
            this.search.enrolment_year_id=this.year[0].id;
            this.getAllExamSession();
          }else{
            this.getAllExamSession();
          }
        }
      });
    },
    getAllScore() {
      score.getScores().then(response => {
        this.scores = response.data.scores;
      });
    },
    searchExamSession(){
      this.page =1;
      this.getAllExamSession();
    },
    createExamSession(){
      this.$router.push({
        name:'exam-session-create',
      })
    },
    editExamSession(examSessionId){
      this.$router.push({
        name:'exam-session-edit',
        params:{examSessionId: examSessionId}
      })
    },
    deleteExamSession(id, hasCandidate){
      if(hasCandidate){
        this.$snotify.error('This exam session cannot be deleted as there are enrolments enrolled');
        return false;
      }else{
        this.$confirm({
          message: `Are you sure? `,
          button: {
              no: "No",
              yes: "Yes",
          },
          callback: (confirm) => {
            if (confirm) {
              examSession
              .delete(id)
              .then((response) => {
                  this.getAllExamSession()
                  this.$snotify.success("Exam session deleted");
              })
              .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
              });
            }
          }
        });
      }
    },

  }
}
</script>

<style lang="sass">
table
  th.sorted
    &.asc::after
      display: inline-block
      content: '▼'
      color: red

    &.desc::after
      display: inline-block
      content: '▲'
      color: red

</style>
